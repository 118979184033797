@font-face {
	font-family: 'Proxima Vara';
	src: url('../fonts/proxima-vara-roman.eot');
	src: url('../fonts/proxima-vara-roman.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima-vara-roman.woff2') format('woff2'),
	     url('../fonts/proxima-vara-roman.woff') format('woff'),
	     url('../fonts/proxima-vara-roman.ttf') format('truetype'),
	     url('../fonts/proxima-vara-roman.svg') format('svg');
    font-weight: 100 900;
    font-stretch: 50% 100%;
}
